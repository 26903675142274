import { writable, Writable } from "svelte/store";
import { getContext, setContext } from "svelte";
import type { AuthenticationMode, CodeVerification } from "./types";
const context_key = {};

interface StoreProps {
  mode: AuthenticationMode;
  inlineOAuth: boolean;
  hideSignup: boolean;
  preventSignup: boolean;
  disableSmoreLogoLink: boolean;
  hideForgotPassword: boolean;
  backUrl: string | undefined;
  firstName: string;
  lastName: string;
  userId?: string;
  canEditNames: boolean;
  codeVerification?: CodeVerification;
  hideHeaderText?: boolean;
  inviteToken?: string;
  isMagicInvite?: boolean;
}

interface AuthenticationContext extends Writable<StoreProps> {}

function createStore(newslettersStore: StoreProps): AuthenticationContext {
  const store = writable<StoreProps>(newslettersStore);
  return {
    subscribe: store.subscribe,
    set: store.set,
    update: store.update
  };
}

export function getAuthenticationContext(): AuthenticationContext {
  return getContext(context_key) as AuthenticationContext;
}

export function createAuthenticationContext(context: StoreProps): AuthenticationContext {
  const store = createStore(context);
  setContext(context_key, store);
  return store;
}

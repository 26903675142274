<script lang="ts">
  import { createEventDispatcher } from "svelte";

  const dispatcher = createEventDispatcher();

  function goBackToLogin() {
    dispatcher("modeChanged", "login");
  }
</script>

<h2 class="mb-4 font-bold text-nxgray-600 text-18">Now, you'll need to create a password to log in 🔑</h2>
<p class="w-full text-nxgray-400 text-13">Check the email you entered for further instructions on how to create a new password!</p>
<button
  class="flex justify-center w-full py-3 mt-5 font-bold text-white bg-orange-600 rounded outline-none text-15 hover:bg-orange-500 focus:bg-orange-500 clickable focus:outline-none"
  on:click={goBackToLogin}>Back to login</button>

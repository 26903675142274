<script lang="ts">
  import Modal from "@ui/Modal.svelte";
  import { createEventDispatcher } from "svelte";
  import Checkbox from "@ui/Checkbox.svelte";

  const dispatch = createEventDispatcher();

  export let isOpen: boolean = false;
  let checked: boolean = false;
  let isSubmitting = false;
  let showError = false;

  async function submit(data: Record<string, any>) {
    if (!checked) {
      showError = true;
      setTimeout(() => (showError = false), 1600);
      return;
    }

    isSubmitting = true;
    dispatch("acceptedTerms");
    isSubmitting = false;
  }

  function cancel() {
    isOpen = false;
  }
</script>

<Modal bind:isOpen title="Almost there" hideIcon={true} boldTitle={true} --popup-width="430px">
  <span class="text-14 text-nxgray-500"> To complete your sign up please confirm the following by checking the box below: </span>

  <label for="terms" class="relative flex justify-start w-full mt-4 text-sm select-none">
    <div
      class="animate-error box-content absolute transition-all border-dashed border-[1.5px] rounded -top-2 -bottom-2 -left-2 -right-2 0"
      class:border-transparent={!showError}
      class:border-red-600={showError} />
    <Checkbox name="terms" id="terms" autofocus={true} bind:checked required={true} />
    <div class="ml-2 text-nxgray-500 text-14">
      By signing up for a Smore account, you agree to our <a href="https://www.smore.com/terms/" class="text-teal-600">Terms of Service</a>
      and
      <a href="https://www.schoolstatus.com/master-services-agreement" class="text-teal-600">Master Service Agreement</a>
      unless your organization has agreed to other terms in writing.
    </div>
  </label>

  <div class="flex items-center justify-between w-full px-2 py-2 pl-3 mt-4 rounded-b align-center">
    <button
      on:click|preventDefault|stopPropagation={cancel}
      class="transition-colors cursor-pointer text-13 text-nxgray-400 clickable scale-in hover:text-red-600">
      Cancel
    </button>

    <input
      type="submit"
      class="px-3 py-1.5 font-medium text-white transition-colors bg-green-600 rounded shadow clickable text-14"
      on:click|preventDefault|stopPropagation={submit}
      class:hover:bg-green-500={checked}
      class:bg-nxgray-200={isSubmitting || !checked}
      class:text-gray-400={isSubmitting || !checked}
      class:cursor-not-allowed={isSubmitting || !checked}
      class:shadow-none={isSubmitting || !checked}
      value={isSubmitting ? "Continuing..." : "Continue"} />
  </div>
</Modal>

<style>
  .animate-error {
    animation: ping 0.4s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  @keyframes ping {
    50% {
      opacity: 0.2;
    }
  }
</style>

<a
  href="/convert-facebook"
  class="flex items-center w-full overflow-hidden font-semibold text-white border rounded outline-none text-15 focus:outline-none clickable">
  <div class="google-icon" />
  <div class="pl-4">Continue with Facebook</div>
</a>

<style>
  a {
    --color: #2b6ad0;
    background-color: var(--color);
    border-color: var(--color);
  }
  a:hover {
    --color: #5189e3;
    background-color: var(--color);
    border-color: var(--color);
  }
  .google-icon {
    background-color: white;
    background-image: url("./images/facebook-logo.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 42px;
    height: 42px;
  }
</style>

<script lang="ts">
  import CodeInput from "./CodeInput.svelte";
  import { postJson, ServerError } from "@ui/http";
  import type { CodeVerification, SubmitLoginCodeResult } from "./types";
  import { createEventDispatcher } from "svelte";

  export let errorCode: string | undefined = undefined;
  export let codeVerification: CodeVerification;

  const dispatcher = createEventDispatcher();

  let isSubmitting: boolean = false;
  let isResendingCode: boolean = false;
  let isCodeValid = false;
  let verificationCode: string;

  let isLogin = codeVerification.source === "login";
  let obfuscatedEmail: string;

  $: canSubmit = isCodeValid && !isSubmitting;

  $: obfuscatedEmail = codeVerification.obfuscatedEmail.replace(/\*/g, "∗");

  async function codeChanged(e: { detail: { isValid: boolean; code: string } }) {
    const { isValid, code } = e.detail;
    isCodeValid = isValid;
    verificationCode = code;

    errorCode = undefined;
  }
  async function submit() {
    if (!canSubmit) {
      return;
    }

    try {
      errorCode = undefined;
      isSubmitting = true;

      const result = await postJson<SubmitLoginCodeResult>("/app/auth/submit_login_code", {
        code: verificationCode,
        token: codeVerification.token
      });

      dispatcher("login", result.redirectUrl);
    } catch (e) {
      errorCode = (e as ServerError).code || "generalerror";
    } finally {
      isSubmitting = false;
    }
  }

  async function resendCode() {
    try {
      errorCode = undefined;
      isResendingCode = true;

      await postJson("/app/auth/resend_login_code", {
        token: codeVerification.token
      });
    } catch (e) {
      errorCode = (e as ServerError).code || "generalerror";
    } finally {
      isResendingCode = false;
    }
  }
</script>

<div>
  <h2 class="mb-4 font-bold text-nxgray-600 text-18">
    {#if isLogin}
      Welcome back, {codeVerification.firstName}!
    {:else}
      Hi, {codeVerification.firstName}!
    {/if}
  </h2>
  <div class="flex flex-col my-6 text-13">
    {#if isLogin}
      It looks like you're trying to log in but your session expired.
    {:else}
      Hmm, it looks like you're trying to make some changes to your account. You'll just need to authenticate your account first.
    {/if}
    <br />&nbsp;<br />
    {#if isLogin}
      As an added security measure, please enter the 6-digit code we sent to {obfuscatedEmail}.
    {:else}
      Please enter the 6-digit code we sent to {obfuscatedEmail}.
    {/if}
  </div>
  <div class="flex flex-row justify-between mb-6">
    <a
      href="https://help.smore.com"
      target="_blank"
      class="font-medium outline-none link focus:outline-none clickable active:outline-none text-13">Need help?</a>
    <a
      href={""}
      tabindex="-1"
      on:click|preventDefault={resendCode}
      class="font-medium outline-none link focus:outline-none clickable active:outline-none text-13"
      style={isResendingCode ? "color: rgb(187 187 187)!important" : ""}
      class:pointer-events-none={isResendingCode}>
      {#if isResendingCode}
        Sending new code...
      {:else}
        Resend code
      {/if}
    </a>
  </div>

  <CodeInput disabled={isSubmitting} on:changed={codeChanged} on:readyToSubmit={submit} />

  <input
    type="submit"
    on:click|preventDefault|stopPropagation={submit}
    disabled={!canSubmit}
    class="w-full py-3 mt-6 font-bold text-center text-white bg-gray-200 rounded pointer-events-none select-none caret-transparent hover:bg-gray-100 focus:bg-gray-100 text-15"
    class:pointer-events-auto={canSubmit}
    class:bg-orange-600={canSubmit}
    class:clickable={canSubmit}
    class:hover:bg-orange-500={canSubmit}
    class:focus:bg-orange-500={canSubmit}
    value={isSubmitting ? "Submitting..." : "Submit"} />
</div>

<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { validateEmail } from "./validations";
  import { _token } from "@ui/currentUser";

  export let email: string = "";
  export let compromised: boolean = false;

  const dispatcher = createEventDispatcher();

  let emailInput: HTMLInputElement;

  $: [emailValid, emailValidationMessage] = validateEmail(email, "login");
  $: canSubmitResetPasswordForm = emailValid;

  function rememberdPassword() {
    dispatcher("modeChanged", "login");
  }

  onMount(() => {
    if (emailInput) {
      emailInput.focus();
    }
  });
</script>

<h2 class="mb-4 font-bold text-nxgray-600 text-18">Reset your Smore password</h2>
<form class="flex flex-col items-start w-full" method="POST" action="/app/auth/send_reset_password_email">
  <label class="mb-1 text-nxgray-400 text-13" for="email">Email address</label>
  <input type="hidden" name="new_flow" value="1" />
  <input type="hidden" name="compromised" value={compromised} />
  <input type="hidden" name="_token" value={$_token} required />
  <input
    class="w-full text-14"
    class:valid={emailValid}
    type="text"
    name="email"
    autocomplete="email"
    bind:this={emailInput}
    bind:value={email}
    placeholder="name@email.com" />
  <div class="text-red-500 text-13" class:visible={emailValidationMessage} class:invisible={!emailValidationMessage}>
    {emailValidationMessage}
  </div>
  <input
    type="submit"
    disabled={!canSubmitResetPasswordForm}
    class="w-full py-3 mt-4 font-bold text-center text-white bg-gray-200 rounded pointer-events-none select-none hover:bg-gray-100 focus:bg-gray-100 text-15"
    class:pointer-events-auto={canSubmitResetPasswordForm}
    class:clickable={canSubmitResetPasswordForm}
    class:bg-orange-600={canSubmitResetPasswordForm}
    class:hover:bg-orange-500={canSubmitResetPasswordForm}
    class:focus:bg-orange-500={canSubmitResetPasswordForm}
    value="Reset Password" />
</form>
<button
  class="flex justify-center w-full mt-5 font-medium text-teal-600 outline-none text-13 hover:text-teal-500 focus:text-teal-500 clickable focus:outline-none"
  on:click={rememberdPassword}>I remembered my password</button>

<style>
  input:not([type="submit"]) {
    padding-left: theme("padding.3");
    padding-right: theme("padding.3");
    padding-top: theme("padding.2");
    padding-bottom: theme("padding.2");
    border-radius: theme("borderRadius.DEFAULT");
    border-width: theme("borderWidth.DEFAULT");
    color: theme("textColor.nxgray.600");
    outline-offset: theme("outlineOffset.2");
    outline-width: theme("outlineWidth.2");
    outline-color: theme("outlineColor.transparent");
    outline-style: solid;
  }

  input:not([type="submit"]):not(.valid):not(:placeholder-shown):focus,
  input:not([type="submit"]):not(.valid):not(:placeholder-shown) {
    border-color: theme("borderColor.red.500");
  }

  input:not([type="submit"]).valid:focus,
  input:not([type="submit"]).valid {
    border-color: theme("borderColor.green.500");
  }
</style>

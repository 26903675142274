<script lang="ts">
  import { onMount, createEventDispatcher } from "svelte";
  import { Key } from "@ui/keyCodes";

  const dispatcher = createEventDispatcher();

  const CODE_LENGTH = 6;

  export let disabled = false;

  let isValid = false;

  const inputElements: HTMLInputElement[] = Array(CODE_LENGTH);

  onMount(() => {
    inputElements[0].focus();
  });

  function keyDown(e: KeyboardEvent) {
    const { key } = e;

    if (![Key.ArrowDown, Key.ArrowRight, Key.ArrowUp, Key.ArrowLeft, Key.Backspace, Key.Delete, Key.Tab, Key.Enter].includes(key as Key)) {
      // Unsupported key
      return;
    }

    const inputElement = e.target as unknown as HTMLInputElement;
    const inputIndex = inputElements.indexOf(inputElement);

    switch (key) {
      case Key.ArrowDown:
      case Key.ArrowRight:
        const nextElement = inputElements[inputIndex + 1];
        if (nextElement) {
          nextElement.focus();
        }
        break;

      case Key.Backspace:
      case Key.ArrowUp:
      case Key.ArrowLeft:
        const previousElement = inputElements[inputIndex - 1];
        if (previousElement) {
          previousElement.focus();
        }
        break;
    }

    if (key === Key.Backspace || key === Key.Delete) {
      // Delete the current input. On Backspace, we also move to the previous input in the previous step.
      inputElement.value = "";
      if (key === Key.Backspace) {
        // We already deleted the current input. Don't do anything more.
        e.preventDefault();
      }
      // We just deleted a digit -> notify the change
      refreshAndDispatch("changed");
    }
  }

  function paste(e: ClipboardEvent) {
    let input = (e.clipboardData || (window as unknown as any).clipboardData).getData("text");

    const inputElement = e.target as unknown as HTMLInputElement;
    handleInput(input, inputElement);
  }

  function inputChanged(e: Event & { data?: string }) {
    const input = e.data;

    if (!input) {
      return;
    }

    const inputElement = e.target as unknown as HTMLInputElement;
    handleInput(input, inputElement);
  }

  function handleInput(input: string, inputElement: HTMLInputElement) {
    let inputIndex = inputElements.indexOf(inputElement);

    let nextElement;
    let isDirty = false;

    for (let i = 0; i < input.length; i++) {
      const char = input.charAt(i);
      const digit = parseInt(char);

      if (!digit && digit !== 0) {
        inputElements[inputIndex].value = "";
        continue;
      }

      inputElements[inputIndex].value = char;
      inputIndex++;

      isDirty = true;

      nextElement = inputElements[inputIndex];

      if (inputIndex >= CODE_LENGTH) {
        // No more digits to enter
        break;
      }
    }

    if (!isDirty) {
      return;
    }

    refreshAndDispatch("changed");

    if (nextElement) {
      nextElement.focus();
    } else {
      if (isValid) {
        setTimeout(() => dispatcher("readyToSubmit"), 0);
      }
    }
    return;
  }

  function refreshAndDispatch(event: string, data: {} = {}) {
    const code = inputElements.map((inputEl) => inputEl.value || "_").join("");
    isValid = code.replace("_", "").length === CODE_LENGTH;

    dispatcher(event, { code, isValid });
  }

  function select(inputElement: HTMLInputElement) {
    if (inputElement) {
      window.requestAnimationFrame(() => {
        try {
          inputElement.type = "text";
          inputElement.setSelectionRange(0, 1);
          inputElement.type = "number";
        } catch {
          // There's nothing we can do here :(
        }
      });
    }
  }
</script>

<div class="flex flex-row justify-between">
  {#each inputElements as inputElement, index}
    <input
      id={`input-${index}`}
      type="number"
      on:click={() => select(inputElement)}
      on:focus={() => select(inputElement)}
      on:keydown={keyDown}
      on:input|stopPropagation|preventDefault={inputChanged}
      on:paste|stopPropagation|preventDefault={paste}
      bind:this={inputElement}
      class="block h-10 text-center transition-all bg-left bg-no-repeat border-gray-200 rounded form-input text-input focus:border-teal-400 active:border-teal-400"
      class:cursor-not-allowed={disabled}
      class:opacity-50={disabled}
      style="width: 3.25rem;"
      maxlength={1}
      {disabled} />
  {/each}
</div>

<style>
  @media (max-width: 640px) {
    .form-input {
      width: 2.5rem !important;
    }
  }
  .form-input {
    caret-color: transparent;
  }
  .form-input::selection,
  .form-input::-moz-selection {
    color: #3cb9ac;
  }
  .form-input {
    --border-focus: 0 0 0 4px #dcf4f1;
    padding-top: 0.35em;
    padding-bottom: 0.35em;
    -moz-appearance: textfield;
  }
  .form-input:focus {
    outline: none;
    box-shadow: var(--border-focus);
  }
  .form-input::-webkit-outer-spin-button,
  .form-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>

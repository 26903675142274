<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { getAuthenticationContext } from "./context";

  export let mode: "signup" | "login";
  export let preventSignup: boolean = false;
  export let requireTOSAcceptance: boolean = false;

  const dispatcher = createEventDispatcher();
  const context = getAuthenticationContext();

  let buttonElement: HTMLButtonElement;

  function onClick(e: MouseEvent) {
    if (!$context.inlineOAuth) {
      e.stopPropagation();
      e.preventDefault();
    }

    if (requireTOSAcceptance) {
      dispatcher("clickWhileTOSRequired");
      return;
    }

    continueWithGoogle();
  }

  export function continueWithGoogle() {
    const url = new URL(`/app/users/google_connect?mode=${mode}`, document.location.href);

    if (mode === "signup") {
      url.searchParams.append("signup_button", "signup-page-google-connect");
    }
    if ($context.inviteToken && $context.inviteToken.length > 0) {
      url.searchParams.append("invite_token", $context.inviteToken);
    }

    document.location.href = url.toString();
  }
</script>

<button
  bind:this={buttonElement}
  on:click={onClick}
  id={$context.inlineOAuth ? undefined : "google-connect-button"}
  data-prevent-signup={preventSignup ? "true" : ""}
  class="flex items-center w-full overflow-hidden font-semibold text-white border rounded outline-none text-15 focus:outline-none clickable">
  <div class="google-icon" />
  <div class="pl-4">Continue with Google</div>
</button>

<style>
  button {
    --color: #5187ec;
    background-color: var(--color);
    border-color: var(--color);
  }
  button:hover {
    --color: #6a9eff;
    background-color: var(--color);
    border-color: var(--color);
  }
  .google-icon {
    background-color: white;
    background-image: url("./images/google-logo.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 42px;
    height: 42px;
  }
</style>
